import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CloseFilledIcon, SaveOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Button, Checkbox } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import Cardinal from 'lib/cardinal-songbird';
import { CybersourceFingerprint } from 'lib/cybersource-fingerprint';
import { showErrorSnack } from 'modules/error';
import { sessionSuccessRedirectUrl } from 'modules/session-details';
import Track, { CreditCardCancelCTA, CreditCardSaveCTA } from 'modules/tracking';
import {
  addCcResp,
  postTransactionAddCc,
  postTransactionAddCcSca,
  transactionsCcPrepareResp,
  transactionsKeyId,
} from 'modules/transactions-cc';
import { COPAS_PAYMENT_STATUS, CREDIT_CARD_DEFAULT, TRACKING_EVENT_STATUS } from 'utils/constants';
import st from 'utils/shared-translations';

import './credit-card-save-button.css';

interface CreditCardSaveButtonProps {
  isSubmitting: boolean;
  isFormInvalid: boolean;
  flexFormToken: string;
  formValues: any;
  setFormValues: any;
}

let isSubmit = true;

const CreditCardSaveButton: FC<CreditCardSaveButtonProps> = ({
  isSubmitting,
  isFormInvalid,
  flexFormToken,
  formValues,
  setFormValues,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [otpJWT, setOtpJWT] = useState('');

  // Session selectors
  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);

  // Transaction selectors
  const selectedTransactionsKeyId = useAppSelector(transactionsKeyId);
  const selectedTransactionPrepareResp = useAppSelector(transactionsCcPrepareResp);
  const selectedAddCreditCardResp = useAppSelector(addCcResp);

  /**
   * Second Payment save API if the OTP is submitted correctly.
   */
  useEffect(() => {
    if (otpJWT && selectedAddCreditCardResp.paymentMethodKey) {
      dispatch(
        postTransactionAddCcSca({
          fingerPrintSessionId: CybersourceFingerprint.id,
          cardinalJwt: otpJWT,
          paymentMethodKey: selectedAddCreditCardResp.paymentMethodKey,
          setAsDefault: formValues[CREDIT_CARD_DEFAULT],
        }),
      )
        .unwrap()
        .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
      setOtpJWT('');
    }
  }, [dispatch, formValues, otpJWT, selectedAddCreditCardResp]);

  /**
   * Based on the response from 1st or 2nd payment Save API,
   * if payerAuth result is available then show 3DS challenge,
   * if Status is failed, show error message,
   * if paymentMethodKey is available then redirect the user to Payment method page.
   */
  useEffect(() => {
    if (selectedAddCreditCardResp) {
      const { payerAuthEnrollmentResult, paymentMethodKey, status } = selectedAddCreditCardResp;
      if (payerAuthEnrollmentResult) {
        const cardinalPayload = {
          AcsUrl: payerAuthEnrollmentResult.acsUrl,
          Payload: payerAuthEnrollmentResult.paReq,
        };
        const orderPayload = {
          OrderDetails: {
            TransactionId: payerAuthEnrollmentResult.authenticationTransactionId,
          },
        };
        Cardinal.show3DSChallange(cardinalPayload, orderPayload);
      } else if (status === COPAS_PAYMENT_STATUS.FAILED) {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        Track(CreditCardSaveCTA, { status: TRACKING_EVENT_STATUS.copasFailure });
        return;
      } else if (paymentMethodKey) {
        Track(CreditCardSaveCTA, { status: TRACKING_EVENT_STATUS.success });
        window.location.assign(selectedSuccessRedirectUrl);
      }
    }
  }, [dispatch, selectedAddCreditCardResp, selectedSuccessRedirectUrl]);

  /**
   * First payment method save API.
   */
  const call1stPaymentSaveAPI = () => {
    dispatch(
      postTransactionAddCc({
        fingerPrintSessionId: CybersourceFingerprint.id,
        cardinalJwt: selectedTransactionPrepareResp.tokenString || '',
        flexResponse: flexFormToken,
        flexKeyId: selectedTransactionsKeyId,
        setAsDefault: formValues[CREDIT_CARD_DEFAULT],
      }),
    )
      .unwrap()
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  /**
   * After OTP is submitted successfully, store it locally to be used in 2nd payment save API.
   */
  const handleOTPChallenge = (otpResp: any, jwt: string) => {
    if (otpResp.ErrorNumber === 0 && otpResp.ErrorDescription === 'Success') {
      // If user cancels the OTP challenge.
      if (otpResp.Payment?.ExtendedData?.ChallengeCancel === '01') {
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
        return;
      }
      setOtpJWT(jwt);
      return;
    }
    dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
  };

  /**
   * Once Cardinal is successfully triggered, it calls save payment method.
   */
  const triggerCardinal = () => {
    const parsedToken = JSON.parse(atob(flexFormToken.split('.')[1]));
    return Cardinal.trigger(parsedToken.data.number.slice(0, 6))
      .then(call1stPaymentSaveAPI)
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  /**
   * Configure Cardinal then initalizes with sca token.
   * Cardinal can been initlized only once unless the browser is refreshed.
   * This is limitation from Cardinal side, since they don`t have a Cardinal session-destroy method.
   */
  const setupCardinal = () => {
    Cardinal.configure()
      .then(() => {
        // Initialize Cardinal with SCA Token
        Cardinal.initialize({
          referenceId: selectedTransactionPrepareResp.referenceId,
          isScaEnabledForMid: selectedTransactionPrepareResp.isScaEnabledForMid,
          tokenString: selectedTransactionPrepareResp.tokenString,
        });

        // Event listener after the cardinal is initiated. Triggers bin process.
        Cardinal.addEventListener('payments.setupComplete', () => triggerCardinal());

        // Event listener after the OTP is submitted.
        Cardinal.addEventListener('payments.validated', (otpResp, jwt) => handleOTPChallenge(otpResp, jwt));
      })
      .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
  };

  // Once user submits the form
  if (isSubmit && flexFormToken && selectedTransactionsKeyId) {
    Track(CreditCardSaveCTA, {
      status: TRACKING_EVENT_STATUS.click,
      isDefault: formValues[CREDIT_CARD_DEFAULT] || false,
    });
    isSubmit = false;
    if (selectedTransactionPrepareResp.isScaEnabledForMid) {
      setupCardinal();
    } else {
      call1stPaymentSaveAPI();
    }
  }

  // Once user cancels the form, take them to Payment method page.
  const cancelForm = () => {
    Track(CreditCardCancelCTA, {});
    window.location.assign(selectedSuccessRedirectUrl);
  };

  // Set default card handler
  const handleDefaultCard = (event: any) => {
    const { checked } = event.target;
    setFormValues({
      ...formValues,
      [CREDIT_CARD_DEFAULT]: checked,
    });
  };

  return (
    <section className="credit-card-save-button">
      <Checkbox disabled={isSubmitting} onChange={handleDefaultCard}>
        {intl.formatMessage(st['payment.method.set.as.default'])}
      </Checkbox>
      <section>
        <Button
          variant="tertiary-neutral"
          leadingIcon={<CloseFilledIcon />}
          onClick={cancelForm}
          disabled={isSubmitting}
          className="credit-card-save-button__cancel"
        >
          {intl.formatMessage(st['creditcardform.cta.cancel'])}
        </Button>
        <Button
          variant="primary"
          type="submit"
          leadingIcon={<SaveOutlinedIcon />}
          disabled={isFormInvalid || isSubmitting}
        >
          {intl.formatMessage(st['creditcardform.cta.save'])}
        </Button>
      </section>
    </section>
  );
};

export default CreditCardSaveButton;
