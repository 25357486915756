import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { CybersourceFingerprint } from 'lib/cybersource-fingerprint';
import { postProcessRenewal } from 'modules/cog';
import { isProcessRenewalLoading } from 'modules/cog/cog-selector';
import { showErrorSnack } from 'modules/error';
import { sessionId } from 'modules/global-wrapper';
import { isDeletePaymentMethodLoading, isSetDefaultPaymentLoading } from 'modules/payment-methods';
import {
  sessionCurrencyCode,
  sessionProducts,
  sessionQuoteId,
  sessionTotalAmount,
  sesssionCancelRedirectUrl,
} from 'modules/session-details';
import Track, { PaynowCTA, Revenue } from 'modules/tracking';
import {
  postTransactionUseDDPay,
  transactionsDDIsLoading,
  useDDPayFailed,
  useDDPayPending,
  useDDPaySuccess,
} from 'modules/transaction-dd';
import {
  postTransactionUsePaypalPay,
  transactionsPaypalIsLoading,
  usePaypalPayFailed,
  usePaypalPayPending,
  usePaypalPaySuccess,
} from 'modules/transaction-paypal';
import {
  postTransactionUseAchPay,
  transactionsAchIsLoading,
  useAchPayFailed,
  useAchPayPending,
  useAchPaySuccess,
} from 'modules/transactions-ach';
import { postProcessQuote } from 'modules/ucs';
import { isProcessQuoteLoading } from 'modules/ucs/ucs-selector';
import {
  PAYMENT_METHODS_TYPES,
  PURCHASE_FLOW_QUOTE,
  PURCHASE_FLOW_RENEWALS,
  PURCHASE_FLOW_SUBSCRIPTION,
  SEARCH_PARAMS,
} from 'utils/constants';
import st from 'utils/shared-translations';
import { getProductAdminDataFromCookie, removeProductAdminDataFromCookie } from 'utils/ui-utils';

interface ElectronicBuyNowProps {
  paymentMethodKey: string;
  paymentType: string;
}

let isDisabled = false;

const ElectronicBuyNow: FC<ElectronicBuyNowProps> = ({ paymentMethodKey, paymentType }): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isComponentLoading, setComponentLoading] = useState(false);

  const selectedSessionId = useAppSelector(sessionId);
  const selectedQuoteProcessIsLoading = useAppSelector(isProcessQuoteLoading);
  const selectedProcessRenewalIsLoading = useAppSelector(isProcessRenewalLoading);
  const renewalDate = localStorage.getItem(SEARCH_PARAMS.renewalDate) || '';

  // Session details selectors
  const selectedQuoteTotalPrice = useAppSelector(sessionTotalAmount);
  const selectedQuoteKey = useAppSelector(sessionQuoteId);
  const selectedSessionProducts = useAppSelector(sessionProducts);
  const selectedSessionCurrencyCode = useAppSelector(sessionCurrencyCode);
  const selectedSessionCancelUrl = useAppSelector(sesssionCancelRedirectUrl);

  // ACH selectors
  const selectedTransactionAchLoading = useAppSelector(transactionsAchIsLoading);
  const selectedTransactionUseAchFailed = useAppSelector(useAchPayFailed);
  const selectedTransactionUseAchSuccess = useAppSelector(useAchPaySuccess);
  const selectedTransactionUseAchPending = useAppSelector(useAchPayPending);

  // DD selectors
  const selectedTransactionDDLoading = useAppSelector(transactionsDDIsLoading);
  const selectedTransactionUseDDFailed = useAppSelector(useDDPayFailed);
  const selectedTransactionUseDDSuccess = useAppSelector(useDDPaySuccess);
  const selectedTransactionUseDDPending = useAppSelector(useDDPayPending);

  // Paypal selectors
  const selectedTransactionPaypalLoading = useAppSelector(transactionsPaypalIsLoading);
  const selectedTransactionUsePaypalFailed = useAppSelector(usePaypalPayFailed);
  const selectedTransactionUsePaypalSuccess = useAppSelector(usePaypalPaySuccess);
  const selectedTransactionUsePaypalPending = useAppSelector(usePaypalPayPending);

  const selectedDeletePaymentMethodIsLoading = useAppSelector(isDeletePaymentMethodLoading);
  const selectedSetDefaultPaymentIsLoading = useAppSelector(isSetDefaultPaymentLoading);

  const productAdminData = getProductAdminDataFromCookie();

  const processQuote = useCallback(() => {
    const data = {
      quoteCode: selectedQuoteKey,
      payload: {
        grossAmount: selectedQuoteTotalPrice,
        sessionId: selectedSessionId,
        paymentType,
        ...(Object.keys(productAdminData).length > 0 && { productAdmin: productAdminData }),
      },
    };

    dispatch(postProcessQuote(data))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: selectedSessionCancelUrl.includes('subscriptions')
            ? PURCHASE_FLOW_SUBSCRIPTION
            : PURCHASE_FLOW_QUOTE,
          paymentMethod: paymentType,
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      )
      .catch(() => {})
      .finally(() => Object.keys(productAdminData).length > 0 && removeProductAdminDataFromCookie());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    selectedSessionCancelUrl,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
    paymentType,
  ]);

  const processRenwal = useCallback(() => {
    const payload = {
      date: renewalDate,
      sessionId: selectedSessionId,
      amount: selectedQuoteTotalPrice.toString(),
    };
    dispatch(postProcessRenewal(payload))
      .unwrap()
      .then(() =>
        Track(Revenue, {
          orderList: selectedSessionProducts,
          currencyCode: selectedSessionCurrencyCode,
          purchaseFlow: PURCHASE_FLOW_RENEWALS,
          paymentMethod: paymentType,
          quoteId: selectedQuoteKey,
          amount: selectedQuoteTotalPrice,
        }),
      )
      .catch(() => {});
  }, [
    dispatch,
    selectedQuoteKey,
    selectedQuoteTotalPrice,
    renewalDate,
    selectedSessionCurrencyCode,
    selectedSessionId,
    selectedSessionProducts,
    paymentType,
  ]);

  useEffect(() => {
    if (selectedTransactionUseAchFailed || selectedTransactionUseDDFailed || selectedTransactionUsePaypalFailed) {
      dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure']));
    }
  }, [dispatch, selectedTransactionUseAchFailed, selectedTransactionUseDDFailed, selectedTransactionUsePaypalFailed]);

  useEffect(() => {
    if (
      selectedTransactionUseAchSuccess ||
      selectedTransactionUseDDSuccess ||
      selectedTransactionUsePaypalSuccess ||
      selectedTransactionUseDDPending ||
      selectedTransactionUseAchPending ||
      selectedTransactionUsePaypalPending
    ) {
      renewalDate ? processRenwal() : processQuote();
    }
  }, [
    selectedTransactionUseAchSuccess,
    selectedTransactionUseDDSuccess,
    processRenwal,
    processQuote,
    renewalDate,
    selectedTransactionUseDDPending,
    selectedTransactionUseAchPending,
    selectedTransactionUsePaypalSuccess,
    selectedTransactionUsePaypalPending,
  ]);

  // Pay now button click handler
  const payNow = () => {
    isDisabled = true;
    Track(PaynowCTA, { paymentType, paymentMethodKey: paymentMethodKey.slice(-5) });
    setComponentLoading(true);

    const payload = {
      fingerPrintSessionId: CybersourceFingerprint.id,
      paymentMethodKey: paymentMethodKey,
    };

    switch (paymentType) {
      case PAYMENT_METHODS_TYPES.ach:
        dispatch(postTransactionUseAchPay(payload))
          .unwrap()
          .catch(() => {
            dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
            setComponentLoading(false);
          });
        break;

      case PAYMENT_METHODS_TYPES.dd:
        dispatch(postTransactionUseDDPay(payload))
          .unwrap()
          .catch(() => {
            dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
            setComponentLoading(false);
          });
        break;

      case PAYMENT_METHODS_TYPES.paypal:
        dispatch(postTransactionUsePaypalPay(payload))
          .unwrap()
          .catch(() => {
            dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
            setComponentLoading(false);
          });
        break;
    }
  };

  return (
    <Button
      isLoading={
        isComponentLoading ||
        selectedQuoteProcessIsLoading ||
        selectedProcessRenewalIsLoading ||
        selectedTransactionAchLoading ||
        selectedTransactionDDLoading ||
        selectedTransactionPaypalLoading
      }
      disabled={
        isDisabled || selectedDeletePaymentMethodIsLoading || selectedSetDefaultPaymentIsLoading || !paymentMethodKey
      }
      size="large"
      fullWidth
      onClick={payNow}
    >
      {intl.formatMessage(st['pay.now.cta'])}
    </Button>
  );
};

export default ElectronicBuyNow;
