export const RECEIPIENT = 'GoTo Technologies';
export const CONTACT_PRODUCT_SALES = 'https://www.goto.com/learn-more';

export const supportedLocales = ['de_DE', 'en_US', 'es_ES', 'fr_FR', 'it_IT', 'ja_JP', 'ko_KR', 'pt_BR', 'zh_CN'];

export const THEMES = Object.freeze({
  light: 'LIGHT',
  dark: 'DARK',
});

export const SEARCH_PARAMS = Object.freeze({
  locale: 'locale',
  theme: 'theme',
  partnerType: 'partnerType',
  renewalDate: 'renewalDate',
  redirect: 'redirect',
  baToken: 'ba_token',
});

export const PARTNER_TYPES = Object.freeze({
  parent: 'PARENT',
  child: 'CHILD',
});

export const PAYMENT_METHODS_TYPES = Object.freeze({
  cc: 'CREDIT_CARD',
  dd: 'DIRECT_DEBIT',
  // ddSepa: 'DIRECT_DEBIT_SEPA', // used in all EU countries with EUR
  // ddBacs: 'DIRECT_DEBIT_BACS', // used in UK with GBP
  // ddBecs: 'DIRECT_DEBIT_BECS', // used in Australia with AUD
  // ddPad: 'DIRECT_DEBIT_PAD', // used in Canada with CAD
  ach: 'ACH',
  // razorpay: 'RAZORPAY',
  paypal: 'PAYPAL',
});

export const PAYMENT_METHOD_TYPES = Object.freeze({
  [PAYMENT_METHODS_TYPES.cc]: ['CREDIT_CARD', 'CREDIT_CARD_WITH_SCA'],
  [PAYMENT_METHODS_TYPES.dd]: ['DIRECT_DEBIT_SEPA'],
  [PAYMENT_METHODS_TYPES.ach]: ['ACH'],
  [PAYMENT_METHODS_TYPES.paypal]: ['PAYPAL'],
});

export const PAY_NOW_INTENT = Object.freeze({ ...PAYMENT_METHODS_TYPES, add: 'ADD' });

// Credit Card
export const CREDIT_CARD_SAVE = 'CreditCardSave';
export const CREDIT_CARD_ADD_PAY = 'CreditCardAddPay';
export const CREDIT_CARD_NUMBER = 'number';
export const CREDIT_CARD_CVV = 'securityCode';
export const CREDIT_CARD_EXP = 'month-year';
export const CREDIT_CARD_DEFAULT = 'creditCardDefault';

// Direct Debit
export const DD_SEPA_DETAILS = Object.freeze({
  crditorName: 'LogMeIn Inc.',
  creditorIdentifier: 'GB53ZZZSDDBARC000007495896029',
  paymentType: 'Recurring payment',
  mandateReference: 'LOGMEINIE-ZE23JMSG',
  streetAddress: 'The Reflector, 10 Hanover Quay',
  city: 'Dublin 2',
  postalCode: 'D02R573',
  Country: 'Republic of Ireland',
});

// ACH
export const achAccountTypeList = ['Corporate', 'Checking', 'Savings'];

// Classic pass product families
export const CENTRAL_PRODUCT_FAMILY = 'Central';
export const PRO_PRODUCT_FAMILY = 'Pro';
export const HAMACHI_PRODUCT_FAMILY = 'Hamachi';

export const classicProductFamilyKey: ReadonlyArray<string> = [
  CENTRAL_PRODUCT_FAMILY,
  PRO_PRODUCT_FAMILY,
  HAMACHI_PRODUCT_FAMILY,
];

export const PURCHASE_FLOW_SUBSCRIPTION = 'subscription';
export const PURCHASE_FLOW_QUOTE = 'quote';
export const PURCHASE_FLOW_RENEWALS = 'renewals';

export const COPAS_PAYMENT_INTENT = Object.freeze({
  PAYMENT_METHOD_CHANGE_ONLY: 'PaymentMethodChangeOnly',
  PAYMENT_OR_PAYMENT_METHOD_CHANGE: 'PaymentOrPaymentMethodChange',
});

export const COPAS_PAYMENT_STATUS = Object.freeze({
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
});

export const TRACKING_EVENT_STATUS = Object.freeze({
  start: 'start',
  click: 'click',
  copasFailure: 'copas failure',
  success: 'success',
});
