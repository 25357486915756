/**
 * Layout
 * Please update any global style here!
 */

import { useEffect, useState } from 'react';
import { LaunchScreen } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import CybersourceFingerprint from 'lib/cybersource-fingerprint';
import { accountKey } from 'modules/global-wrapper';
import { postPaymentMethods } from 'modules/payment-methods';
import { postSessionDetails, sessionCountry, sessionIsLoading } from 'modules/session-details';
import Track, { OnePayLanding } from 'modules/tracking';
import { SEARCH_PARAMS } from 'utils/constants';
import CoreView from './core-view';

const CoreContainer = () => {
  const dispatch = useAppDispatch();

  const [isComponentLoading, setComponentLoading] = useState<boolean>(true);
  const globalTheme = localStorage.getItem(SEARCH_PARAMS.theme);

  const selectedSessionIsLoading = useAppSelector(sessionIsLoading);
  const selectedSessionCountry = useAppSelector(sessionCountry);
  const selectedAccountKey = useAppSelector(accountKey);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(OnePayLanding, {});
    }
  }, [selectedAccountKey]);

  /**
   * Load cybersource fingerprint for the billing country
   */
  useEffect(() => {
    if (selectedSessionCountry) {
      CybersourceFingerprint.load(selectedSessionCountry)
        .then(() => setComponentLoading(false))
        .catch(() => setComponentLoading(false));
    }
  }, [selectedSessionCountry]);

  /**
   * Updating the body style for Dark mode.
   * ChameleonThemeProvider isn't working as expected.
   *  */
  useEffect(() => {
    const { body } = document;
    if (body) {
      body.style.backgroundColor = globalTheme === 'dark' ? '#25282d' : '#fff';
      body.style.color = globalTheme === 'dark' ? '#fff' : '#25282d';
    }
  }, [globalTheme]);

  /**
   * Calling all necessary APIs before mounting the routed component
   */
  useEffect(() => {
    if (selectedAccountKey) {
      dispatch(postSessionDetails());
      dispatch(postPaymentMethods());
    }
  }, [dispatch, selectedAccountKey]);

  // TODO: check if we need selectedPaymentsIsLoading. It's removed as it caused issues in Pay now flow of PayPal, due to unmount and mount of CoreView.
  const isLoading = isComponentLoading || selectedSessionIsLoading;

  return <>{isLoading ? <LaunchScreen /> : <CoreView />}</>;
};

export default CoreContainer;
