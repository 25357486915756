import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { BankAccountOutlinedIcon, BankCardOutlinedIcon, PlaceholderOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Button, RadioButton, RadioGroup, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { paymentMethodsAdditionCount, paymentMethodTypes, setPayNowIntent } from 'modules/payment-methods';
import Track, { PaynowContinueAddNewMethod } from 'modules/tracking';
import { PARTNER_TYPES, PAYMENT_METHOD_TYPES, PAYMENT_METHODS_TYPES, SEARCH_PARAMS } from 'utils/constants';
import st from 'utils/shared-translations';

import './payment-method-options.css';

const PaymentMethodOptions: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const partnerType = localStorage.getItem(SEARCH_PARAMS.partnerType);

  const [paymentMethod, setPaymentMethod] = useState('');

  const selectedPaymentTypes = useAppSelector(paymentMethodTypes);
  const selectedPaymentMethodsAdditionCount = useAppSelector(paymentMethodsAdditionCount);

  const isAddPayment = (paymentMethodType: keyof typeof PAYMENT_METHOD_TYPES) =>
    selectedPaymentTypes.some((type) => PAYMENT_METHOD_TYPES?.[paymentMethodType].includes(type)) &&
    !!selectedPaymentMethodsAdditionCount[paymentMethodType];

  const handleContinue = () => {
    Track(PaynowContinueAddNewMethod, { paymentType: paymentMethod });
    dispatch(setPayNowIntent(paymentMethod));
  };

  const handlePaymentType = (event: any) => {
    const { value } = event.currentTarget;
    setPaymentMethod(value);
  };

  return (
    <>
      <Typography tag="h1" variant="heading-medium">
        {intl.formatMessage(st['payment.methods.header'])}
      </Typography>
      <RadioGroup
        className="payment-method-options"
        aria-label="Payment method options"
        value={''}
        onChange={handlePaymentType}
      >
        {/* TODO: check why the partnerType check was previously there only for CC and not for others */}
        {isAddPayment(PAYMENT_METHODS_TYPES.cc) && partnerType !== PARTNER_TYPES.parent && (
          <RadioButton
            className="payment-method-options__radio"
            value={PAYMENT_METHODS_TYPES.cc}
            aria-label="Credit card"
          >
            <div className="payment-method-options__type">
              <BankCardOutlinedIcon size="24px" />
              <Typography tag="h3" variant="body-small-strong">
                {intl.formatMessage(st['payment.credit.debit.card'])}
              </Typography>
            </div>
          </RadioButton>
        )}
        {isAddPayment(PAYMENT_METHODS_TYPES.dd) && (
          <RadioButton
            className="payment-method-options__radio"
            value={PAYMENT_METHODS_TYPES.dd}
            aria-label="Direct debit"
          >
            <div className="payment-method-options__type">
              <BankAccountOutlinedIcon size="24px" />
              <Typography tag="h3" variant="body-small-strong">
                {intl.formatMessage(st['payment.direct.debit.sepa'])}
              </Typography>
            </div>
          </RadioButton>
        )}
        {isAddPayment(PAYMENT_METHODS_TYPES.ach) && (
          <RadioButton className="payment-method-options__radio" value={PAYMENT_METHODS_TYPES.ach} aria-label="ACH">
            <div className="payment-method-options__type">
              <BankAccountOutlinedIcon size="24px" />
              <Typography tag="h3" variant="body-small-strong">
                {intl.formatMessage(st['payment.direct.debit.ach'])}
              </Typography>
            </div>
          </RadioButton>
        )}
        {isAddPayment(PAYMENT_METHODS_TYPES.paypal) && (
          <RadioButton
            className="payment-method-options__radio"
            value={PAYMENT_METHODS_TYPES.paypal}
            aria-label="PayPal"
          >
            <div className="payment-method-options__type">
              <PlaceholderOutlinedIcon size="24px" />
              <Typography tag="h3" variant="body-small-strong">
                {intl.formatMessage(st['payment.paypal'])}
              </Typography>
            </div>
          </RadioButton>
        )}
      </RadioGroup>

      <Button disabled={!paymentMethod} size="large" fullWidth onClick={handleContinue} aria-label="Continue">
        {intl.formatMessage(st['payment.methods.continue'])}
      </Button>
    </>
  );
};

export default PaymentMethodOptions;
