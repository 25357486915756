import { FC, ReactNode, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChevronLeftOutlinedIcon, GotoAdminPartnerFaviconIcon } from '@getgo/chameleon-icons/react';
import { Button, GotoLogo, Skeleton, Typography } from '@getgo/chameleon-web-react-wrapper';

import PriceInfo from 'components/price-info';
import SessionExpiredModal from 'components/session-expired-modal';
import SessionTimer from 'components/session-timer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { accountKey } from 'modules/global-wrapper';
import {
  paymentMethodsIsLoading,
  paymentTypesIsLoading,
  payNowIntent,
  postPaymentMethodTypes,
  setPayNowIntent,
  sortedPaymentMethodList,
} from 'modules/payment-methods';
import { isSessionExpired, sesssionCancelRedirectUrl } from 'modules/session-details';
import Track, { PaynowBackToCheckout, PaynowLanding } from 'modules/tracking';
import { PAY_NOW_INTENT, PAYMENT_METHODS_TYPES, SEARCH_PARAMS } from 'utils/constants';
import st from 'utils/shared-translations';
import ACHPay from './ach-pay';
import CreditCardPay from './credit-card-pay';
import DirectDebitPay from './direct-debit-pay';
import PaymentMethodsAvailable from './payment-method-available';
import PaymentMethodOptions from './payment-method-options';
import PaypalPay from './paypal-pay';

import './pay-now.css';

const PayNow: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const searchParams = new URLSearchParams(window.location.search);

  const selectedAccountKey = useAppSelector(accountKey);

  const selectedSessionIsExpired = useAppSelector(isSessionExpired);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  const selectedPaymentTypeIsLoading = useAppSelector(paymentTypesIsLoading);
  const selectedPaymentMethodsIsLoading = useAppSelector(paymentMethodsIsLoading);

  const selectedPayNowIntent = useAppSelector(payNowIntent);

  const selectedSortedPaymentMethodList = useAppSelector(sortedPaymentMethodList);

  const isPaypalRedirect = searchParams.get(SEARCH_PARAMS.redirect) === PAYMENT_METHODS_TYPES.paypal;

  useEffect(() => {
    if (selectedAccountKey) {
      Track(PaynowLanding, {});
      dispatch(postPaymentMethodTypes());
    }
  }, [dispatch, selectedAccountKey]);

  useEffect(() => {
    /*
    1. First check if the user is redirected from PayPal, then set the intent to PayPal to call step2 API.
    2. Else if the user has not selected any payment method, then set the intent to add a payment method
    */
    if (isPaypalRedirect) {
      dispatch(setPayNowIntent(PAYMENT_METHODS_TYPES.paypal));
    } else if (selectedSortedPaymentMethodList.length === 0) {
      dispatch(setPayNowIntent(PAY_NOW_INTENT.add));
    }
  }, [dispatch, isPaypalRedirect, selectedSortedPaymentMethodList.length]);

  /**
   * Back button functionality:
   * i) If Payment type is selected then get the user back to:
   *      a. Available Payments page - If paymment methods are already present
   *      b. Payment Method Options page - If payment methods are not present
   * ii) If the user is already on Available Payments page then get the user back to Checkout page.
   */
  const redirectToCheckout = () => {
    if (selectedPayNowIntent) {
      dispatch(setPayNowIntent(selectedSortedPaymentMethodList.length ? '' : PAY_NOW_INTENT.add));
    } else {
      Track(PaynowBackToCheckout, {});
      window.location.assign(selectedCancelRedirectUrl);
    }
  };

  return (
    <div className="pay-now">
      {selectedSessionIsExpired && <SessionExpiredModal redirectType="Checkout" />}
      <nav className="pay-now__nav">
        <Button size="medium" variant="neutral" leadingIcon={<ChevronLeftOutlinedIcon />} onClick={redirectToCheckout}>
          {intl.formatMessage(st['back.to.previous.page'])}
        </Button>
      </nav>
      <header className="pay-now__header">
        <SessionTimer />
        <GotoLogo>
          <GotoAdminPartnerFaviconIcon />
        </GotoLogo>
      </header>
      <aside className="pay-now__aside">
        <PriceInfo />
      </aside>
      <main className="pay-now__main">
        {selectedPaymentTypeIsLoading || selectedPaymentMethodsIsLoading ? (
          <Skeleton size="xxxlarge" className="pay-now__skeleton" />
        ) : (
          {
            '': <PaymentMethodsAvailable />,
            [PAY_NOW_INTENT.add]: <PaymentMethodOptions />,
            [PAY_NOW_INTENT.cc]: <CreditCardPay />,
            [PAY_NOW_INTENT.ach]: <ACHPay />,
            [PAY_NOW_INTENT.dd]: <DirectDebitPay />,
            [PAY_NOW_INTENT.paypal]: <PaypalPay />,
          }[selectedPayNowIntent]
        )}
      </main>
      <footer className="pay-now__footer">
        <Typography variant="caption-medium" color="type-color-secondary">
          <FormattedMessage
            {...st['payment.tnc']}
            values={{
              'tos-link': (msg: ReactNode) => (
                <a
                  href="https://www.goto.com/company/legal/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              'privacy-policy': (msg: ReactNode) => (
                <a href="https://www.goto.com/company/legal/privacy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
              'anti-spam': (msg: ReactNode) => (
                <a href="https://www.goto.com/company/legal/anti-spam-policy" target="_blank" rel="noopener noreferrer">
                  <Typography tag="span" variant="body-small" color="link">
                    {msg}
                  </Typography>
                </a>
              ),
            }}
          />
        </Typography>
      </footer>
    </div>
  );
};

export default PayNow;
